import React, { Component } from "react";
import ajax from "superagent";
import _ from "lodash"; // TODO: load only what's needed form lodash-es
import "../styles/style.css";
import "../styles/prepaid-auth.css";
import EventContainer from "./EventContainer";
import EmbedHeader from "./EmbedHeader";
import PrepaidCustomerAuthPopup from "./PrepaidCustomerAuthPopup";
import StyleOverrides from "./StyleOverrides";
import ahoy from "ahoy.js";
import {
  getParameterByName,
  withCartIdParam,
  removeParam,
  getCookie
} from "../../../utils/BrowserHelper";
import getAlbumId from "../../../utils/GetAlbumId";
import PropTypes from "prop-types";
import ShareSocialPopup from "../../ShareSocialPopup/index";
import { Album, Event, Cart } from "./types";
import BundleMediaSelect from "./albums/BundleSelectMedia";
import BulkTagging from "./BulkTagging";
import Photo from "./albums/Photo";
import DeletePhotosConfirmation from "./DeletePhotosConfirmation";
import ShareIcon from "./vectors/ShareIcon";
import PublishBanner from "../../PublishBanner";
import PublishingOptions from "../../PublishingOptions";
import PromptClearPrepaidCart from "./offerBanners/PromptClearPrepaidCart";
import EventSupportEmailPopup from "./EventSupportEmailPopup";
import SaveCart from "../../SaveCartEmailPopup";
import InvitePhotographerPopup from "../../common/InvitePhotographerPopup";
import Telemetry from "../../../utils/rudderstackAnalytics";
import Sidebar from "./Sidebar";
const wave = require("../../common/images/wave.svg");

interface BrowseEventProps {
  event: Event;
  prepaidCustomer: {
    firstName: string;
    lastName: string;
    email: string;
    eventId: number;
    freePhotosAvailable: number;
  };
  userId: number;
  favoritesGuestUserId: number;
  albumsAPI: string;
  addToCartAPI: string;
  emptyCartAPI: string;
  removeFromCartAPI: string;
  registerCustomerLeadAPI: string;
  registerGuestUserAPI: string;
  addPhotoToFavoritesAPI: string;
  removePhotoFromFavoritesAPI: string;
  userSignedIn: boolean;
  userEmail: string;
  cart: {
    id: number;
    country: string;
    photos: Array<{}>;
    prepaidPhotos: Array<{}>;
    currency: string;
    photoBundleDiscountAvailed: boolean;
    photoBundleDiscountEventId: number;
  };
  initialFilters: object;
  currentUserHasPasswordAccess: boolean;
  enableFilterByText: boolean;
  selfieSearchS3Bucket: string;
  embedded: boolean;
  ios: boolean;
  mobile: boolean;
  photoTimeRange: {
    min: number;
    max: number;
  };
  cookieInjectURL: string;
  uploadInProgress: boolean;
  eventAnalyticsEventsAPI: string;
  eventAnalyticsVisitsAPI: string;
  authenticatePrepaidCustomerAPI: string;
  createPrepaidCustomerAPI: string;
  promptPrepaidAuth: boolean | string;
  logoutPrepaidCustomerAPI: string;
  unlinkPrepaidCustomerAPI: string;
  pageConfiguration: {
    primaryColor: string;
    secondaryColor: string;
    accentColor: string;
  };
  photoBundleDiscountAvailable: boolean;
  availPhotoBundleDiscountAPI: string;
  withdrawPhotoBundleDiscountAPI: string;
  bypassPasswordPrompt: boolean;
  photosAvailable: boolean;
  isAuthorizedForDirectDownloads: boolean;
  eventPassword: string | null;
  authenticateEventPasswordAPI: string;
  galleryURL: string;
  organizationId: number;
  canManageTags: boolean;
  canDeletePhotos: boolean;
  schoolUser: boolean;
  addAllToCartAPI: string;
  hideUploadSelfie: boolean;
  customerCartEmailEnabled: boolean;
  showInvitePhotographerModal: boolean;
  isCustomerOnly: boolean;
  cookiePrepaidAvailed: boolean;
  cookiePrepaidPhotosAvailed: number;
}

interface BrowseEventState {
  cart: Cart;
  prepaidCustomer: {
    firstName: string;
    lastName: string;
    email: string;
    eventId: number;
    freePhotosAvailable: number;
  };
  selfieUploadPopup: boolean;
  promptPrepaidAuth: boolean | string;
  publishState: string;
  discountMessage: string;
  showShareGalleryModal: boolean;
  curationMode: boolean;
  listOfMediaId: Array<{ id: number; hidden: boolean }>;
  showBulkTagging: boolean;
  filterActive: boolean;
  visiblePhotos: Photo[];
  selectAll: boolean;
  deletePhotosPopup: boolean;
  reset: boolean;
  publishPopup: boolean;
  selfiePrepaidStatus: string;
  clearPrepaidCartPopup: boolean;
  clearCartPopup: boolean;
  showEmailpopup: boolean;
  cartFullPrice: string;
  cartDiscountedPrice: string;
  cartHasDiscount: boolean;
  guestUserId: null | number;
  isProtected: boolean;
  albumModeID: string | null;
  album: Album[] | null;
  filterSelection: null | {
    albums?: Array<{}>;
    referenceImage?: string | null;
  };
  showSaveCartModal: boolean;
  showInvitePopup: boolean;
  isSidebarOpen: boolean;
  guestUserEmail: string;
  // Currently photo is typed as any across the BrowseEvent component
  // TODO: Fix this
  photoAddedToCart: any;
}

export default class BrowseEvent extends Component<
  BrowseEventProps,
  BrowseEventState
> {
  static childContextTypes = {
    currentUserHasPasswordAccess: PropTypes.bool,
    enableFilterByText: PropTypes.bool,
    selfieSearchS3Bucket: PropTypes.string,
    iosEmbed: PropTypes.bool,
    uploadInProgress: PropTypes.bool,
    prepaidCustomer: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      eventId: PropTypes.number,
      freePhotosAvailable: PropTypes.number
    }),
    cartPrepaidPhotos: PropTypes.array,
    cartPhotos: PropTypes.array,
    arePrepaidPhotosAvailable: PropTypes.func,
    pageConfiguration: PropTypes.shape({
      primaryColor: PropTypes.string,
      secondaryColor: PropTypes.string,
      accentColor: PropTypes.string
    }),
    cartId: PropTypes.number,
    isEmbed: PropTypes.bool,
    curatePhotos: PropTypes.func,
    reLoadAlbumPhotos: PropTypes.func,
    photoBundleDiscountEventId: PropTypes.number,
    hidePhotographersFilter: PropTypes.bool,
    bypassPasswordPrompt: PropTypes.bool,
    isAuthorizedForDirectDownloads: PropTypes.bool,
    userId: PropTypes.number,
    eventPassword: PropTypes.string,
    authenticateEventPasswordAPI: PropTypes.string,
    selfieUploadPopup: PropTypes.bool,
    organizationId: PropTypes.bool,
    canManageTags: PropTypes.bool,
    clearMediaIds: PropTypes.func,
    selectAll: PropTypes.bool,
    visiblePhotos: PropTypes.array,
    getPhotoId: PropTypes.func,
    setPhotos: PropTypes.func,
    reset: PropTypes.bool,
    resetPhotos: PropTypes.func,
    curationMode: PropTypes.bool,
    setSelectAll: PropTypes.func,
    selfiePrepaidStatus: PropTypes.string,
    showPrepaidClearCartWarning: PropTypes.bool,
    promptClearPrepaidCart: PropTypes.func,
    addAllToCart: PropTypes.func,
    hideUploadSelfie: PropTypes.bool,
    authenticationDone: PropTypes.func,
    setFilterSelections: PropTypes.func,
    albumModeID: PropTypes.string,
    customerCartEmailEnabled: PropTypes.bool,
    cookiePrepaidAvailed: PropTypes.bool,
    cookiePrepaidPhotosAvailed: PropTypes.number
  };
  constructor(props: BrowseEventProps) {
    super(props);
    this.state = {
      cart: props.cart,
      selectAll: false,
      listOfMediaId: [],
      prepaidCustomer: this.props.prepaidCustomer,
      selfieUploadPopup: false,
      discountMessage: "",
      showShareGalleryModal: false,
      showBulkTagging: false,
      curationMode: false,
      filterActive: false,
      deletePhotosPopup: false,
      visiblePhotos: [],
      publishState: this.props.event.publishMode,
      reset: false,
      publishPopup: false,
      guestUserId: null,
      albumModeID: null,
      promptPrepaidAuth:
        getParameterByName("prepaid-auth") ||
        (this.props.event.prepaidCustomersEnabled &&
          !this.props.prepaidCustomer &&
          this.props.promptPrepaidAuth),
      selfiePrepaidStatus: this.defaultSelfiePrepaidStatus(),
      clearPrepaidCartPopup: false,
      showEmailpopup: false,
      clearCartPopup:
        window.location.href.includes("clear-cart-prompt=true") || false,
      isProtected: this.props.event.isProtected,
      filterSelection: { referenceImage: this.getReferenceImage() },
      album: null,
      showSaveCartModal: false,
      showInvitePopup: false,
      isSidebarOpen: false,
      guestUserEmail: ""
    };

    $("body").on("contextmenu", function (e) {
      return false;
    });

    ahoy.configure({
      eventsUrl: props.eventAnalyticsEventsAPI,
      visitsUrl: props.eventAnalyticsVisitsAPI,
      trackVisits: true
    });

    ahoy.trackView({ event_id: props.event.id });
  }
  authenticationDone = () => this.setState({ isProtected: false });
  setFilterSelections = (val: { referenceImage: string }) =>
    this.setState({
      filterSelection: val
    });

  arePrepaidPhotosAvailable = () => {
    if (!this.state.prepaidCustomer) return false;
    if (!this.props.event.prepaidCustomersHardLimitEnabled) return true;

    if (this.prepaidAuthCookie()) {
      if (this.props.cookiePrepaidPhotosAvailed)
        return (
          this.state.cart.prepaidPhotos.length <
          this.props.event.prepaidCustomersPhotoLimit -
            this.props.cookiePrepaidPhotosAvailed
        );
      else {
        return (
          this.state.cart.prepaidPhotos.length <
          this.props.event.prepaidCustomersPhotoLimit
        );
      }
    }
    return (
      this.state.cart.prepaidPhotos.length <
      this.state.prepaidCustomer.freePhotosAvailable
    );
  };

  getChildContext() {
    return {
      currentUserHasPasswordAccess: this.props.currentUserHasPasswordAccess,
      curatePhotos: () => this.curatePhotos(),
      enableFilterByText: this.props.enableFilterByText,
      selfieSearchS3Bucket: this.props.selfieSearchS3Bucket,
      iosEmbed: this.props.embedded && this.props.ios,
      uploadInProgress: this.props.uploadInProgress,
      prepaidCustomer: this.state.prepaidCustomer,
      selectAll: this.state.selectAll,
      cartPrepaidPhotos: this.state.cart ? this.state.cart.prepaidPhotos : null,
      cartPhotos: this.state.cart ? this.state.cart.photos : [],
      arePrepaidPhotosAvailable: () => this.arePrepaidPhotosAvailable(),
      pageConfiguration: this.props.pageConfiguration,
      cartId: this.state.cart ? this.state.cart.id : null,
      isEmbed: this.props.embedded,
      reset: this.state.reset,
      reLoadAlbumPhotos: () => this.reLoadAlbumPhotos(),
      resetPhotos: () => this.setState({ reset: false }),
      photoBundleDiscountEventId: this.state.cart
        ? this.state.cart.photoBundleDiscountEventId
        : null,
      hidePhotographersFilter: this.props.event.hidePhotographersFilter,
      bypassPasswordPrompt: this.props.bypassPasswordPrompt,
      isAuthorizedForDirectDownloads: this.props.isAuthorizedForDirectDownloads,
      userId: this.props.userId,
      eventPassword: this.props.eventPassword,
      authenticateEventPasswordAPI: this.props.authenticateEventPasswordAPI,
      selfieUploadPopup: this.state.selfieUploadPopup,
      organizationId: this.props.organizationId,
      canManageTags: this.props.canManageTags,
      clearMediaIds: () => this.clearMediaIds(),
      curationMode: this.state.curationMode,
      visiblePhotos: this.state.visiblePhotos,
      setSelectAll: () => this.setSelectAll(),
      setPhotos: (val: any) => this.setPhotos(val),
      getPhotoId: (
        photo: {
          id: number;
          hidden: boolean;
        },
        index: any,
        photos: any
      ) => this.getPhotoId(photo, index, photos),
      selfiePrepaidStatus: this.state.selfiePrepaidStatus,
      showPrepaidClearCartWarning: this.showPrepaidClearCartWarning(),
      promptClearPrepaidCart: this.promptClearPrepaidCart,
      addAllToCart: this.addAllToCart,
      hideUploadSelfie: this.props.hideUploadSelfie,
      authenticationDone: this.authenticationDone,
      setFilterSelections: this.setFilterSelections,
      albumModeID: this.state.albumModeID,
      cookiePrepaidAvailed: this.props.cookiePrepaidAvailed,
      cookiePrepaidPhotosAvailed: this.props.cookiePrepaidPhotosAvailed
    };
  }

  showPrepaidClearCartWarning = () =>
    this.props.event.prepaidCustomersPrimaryAuthField == "selfie" &&
    this.props.event.prepaidCustomersEnabled &&
    this.state.cart &&
    this.state.cart.photos.length > 0;

  defaultSelfiePrepaidStatus = () => {
    if (this.props.event.prepaidCustomersPrimaryAuthField == "selfie") {
      if (this.props.prepaidCustomer) return "available";
    }
    return "unavailable";
  };

  setPhotos = (val: any) => {
    this.setState({
      visiblePhotos: val
    });
  };

  promptClearPrepaidCart = () => {
    this.setState({
      clearPrepaidCartPopup: true
    });
  };

  setSelectAll = () => this.setState({ selectAll: false });

  setFilterActive = (val: boolean) => this.setState({ filterActive: val });

  setSelfieUploadPopup = (value: boolean) => {
    this.setState({ selfieUploadPopup: value });
  };

  discountMessage = () => {
    if (this.props.event.hasDiscount) {
      ajax
        .get(
          withCartIdParam(
            this.props.event.discountStatusPath,
            this.state.cart ? this.state.cart.id : null
          )
        )
        .then(res => {
          this.setState({
            discountMessage: res.body.message,
            cartFullPrice: res.body.cart_full_price,
            cartDiscountedPrice: res.body.cart_discounted_price,
            cartHasDiscount: res.body.cart_has_discount
          });
        });
    }
  };

  checkDiscountAndGetStatus = () => {
    if (this.props.event.hasDiscount) {
      this.discountMessage();
    }
  };

  componentDidMount() {
    // @ts-ignore
    this.setState({ albumModeID: getAlbumId() });
    this.props.userSignedIn &&
      getParameterByName("sidebar") &&
      window.innerWidth > 992 &&
      this.toggleSideBar();
    this.checkDiscountAndGetStatus();
    $("#browse-event").ready(() => {
      $("#browse-event-placeholder").hide();
    });
    if (this.props.favoritesGuestUserId) {
      this.setState({ guestUserId: this.props.favoritesGuestUserId });
    }
    if (this.props.showInvitePhotographerModal) {
      Telemetry.track("internal-referrals:event-gallery-ph-referral-btn-shown");
    }
    if (this.showGalleryActions() && this.props.canManageTags) {
      Telemetry.track("curate-btn-shown");
    }
  }

  bundleDiscountAvailedFromAnotherEvent = (photo: any) =>
    this.state.cart &&
    this.state.cart.photoBundleDiscountAvailed &&
    this.state.cart.photoBundleDiscountEventId != photo.album.eventId;

  addAllToCart = (activeFilters: any, valArray: Array<{}>) => {
    const photoIds: number[] = [];
    valArray.forEach((value: Photo) => {
      photoIds.push(value.id);
    });
    if (
      this.props.cart &&
      this.props.event.currency != this.props.cart.currency &&
      this.props.cart.photos.length > 0
    ) {
      //@ts-ignore
      toastr.error(
        `The cart can only contain photos in one currency, currently ${this.props.event.currency}. Clear and try adding again.`
      );
    } else if (
      this.bundleDiscountAvailedFromAnotherEvent(valArray[0]) &&
      this.props.cart &&
      this.props.cart.photos.length > 0
    ) {
      //@ts-ignore
      toastr.error(
        `You have an active package discount applied. This feature does not permit photos to be added from multiple events. Please clear cart or remove offer before proceeding.`,
        { timeOut: 9500 }
      );
    } else {
      ajax
        .post(this.props.addAllToCartAPI)
        .send({
          photo_ids: photoIds,
          activeFilters: activeFilters,
          cart_id: this.state.cart ? this.state.cart.id : null
        })
        .then(res => {
          this.setState(
            {
              cart: res.body.cart
            },
            () => {
              //@ts-ignore
              toastr.success("Added to cart", null, { timeOut: 1000 });
              this.checkDiscountAndGetStatus();
              $.getScript(
                withCartIdParam("/cart_sales_count", this.state.cart.id)
              );
            }
          );
        });
      if (this.bundleDiscountAvailedFromAnotherEvent(valArray[0])) {
        this.setState(
          {
            cart: {
              ...this.state.cart,
              photoBundleDiscountAvailed: false
            }
          },
          () => this.removePhotoBundleDiscount()
        );
      }
    }
  };

  isCartEmpty = () => {
    if (this.state.cart === null) return true;
    return this.state.cart.photos?.length == 0;
  };

  isSaveCartPopupSeen = () =>
    localStorage.getItem("isUserAskedForEmailToSaveCart")
      ? JSON.parse(
          localStorage.getItem("isUserAskedForEmailToSaveCart") as string
        )
      : false;

  canShowEmailPopup = () => {
    const { customerCartEmailEnabled, userSignedIn, event } = this.props;
    const { guestUserId } = this.state;

    const isConditionsMet =
      customerCartEmailEnabled &&
      !guestUserId &&
      this.isCartEmpty() &&
      !userSignedIn;

    if (this.prepaidAuthCookie()) {
      return isConditionsMet;
    } else {
      return (
        isConditionsMet &&
        !this.isSaveCartPopupSeen() &&
        !event.prepaidCustomersEnabled
      );
    }
  };

  afterAddToCart = () => {
    this.checkDiscountAndGetStatus();
    $.getScript(withCartIdParam("/cart_sales_count", this.state.cart.id));
    this.prepaidAuthCookie() && this.handleCookiePrepaidCustomerCreation();
  };

  prepaidAuthCookie = () =>
    this.props.event.prepaidCustomersPrimaryAuthField === "cookie";

  trackCartEmailPopupActions = (eventName: string) => {
    Telemetry.track(eventName, {
      eventId: this.props.event.id,
      eventName: this.props.event.name,
      cartId: this.state.cart ? this.state.cart.id : null
    });
  };

  addPhotoToCart = (photo: any, activeFilters: any) => {
    if (
      this.state.cart &&
      this.props.event.currency != this.state.cart.currency &&
      this.state.cart.photos.length > 0
    ) {
      //@ts-ignore
      toastr.error(
        `The cart can only contain photos in one currency, currently ${this.props.event.currency}. Clear and try adding again.`
      );
    } else if (
      this.bundleDiscountAvailedFromAnotherEvent(photo) &&
      this.state.cart &&
      this.state.cart.photos.length > 0
    ) {
      //@ts-ignore
      toastr.error(
        `You have an active package discount applied. This feature does not permit photos to be added from multiple events. Please clear cart or remove offer before proceeding.`,
        { timeOut: 9500 }
      );
    } else if (
      this.state.cart &&
      this.state.cart.eventIds.length >= 1 &&
      !this.state.cart.eventIds.includes(this.props.event.id) &&
      this.prepaidAuthCookie()
    ) {
      this.setState({
        clearPrepaidCartPopup: true
      });
    } else if (this.canShowEmailPopup()) {
      this.setState({
        showSaveCartModal: true,
        photoAddedToCart: photo,
        filterSelection: activeFilters
      });
    } else {
      ajax
        .post(this.props.addToCartAPI)
        .send({
          photo_id: photo.id,
          activeFilters: activeFilters,
          cart_id: this.state.cart ? this.state.cart.id : null
        })
        .then(res => {
          this.setState(
            {
              cart: res.body.cart
            },
            () => {
              this.afterAddToCart();
              this.trackCartEmailPopupActions(
                "event-gallery:cart-email-popup-displayed"
              );
            }
          );
        });
      if (this.bundleDiscountAvailedFromAnotherEvent(photo)) {
        this.setState(
          {
            cart: {
              ...this.state.cart,
              photoBundleDiscountAvailed: false
            }
          },
          () => this.removePhotoBundleDiscount()
        );
      }
      //@ts-ignore
      (window.dataLayer || []).push({ event: "addToCart" });
      ahoy.track("albumInteraction", {
        secondary_type: "addToCart",
        photo_id: photo.id,
        album_id: photo.album.id,
        event_id: this.props.event.id
      });
    }
  };

  removePhotoFromCart = (photo: any) => {
    let substitutePrepaidPhoto =
      _.includes(this.state.cart.prepaidPhotos, photo.id) &&
      _.head(
        _.difference(this.state.cart.photos, this.state.cart.prepaidPhotos)
      );

    this.setState({
      cart: {
        ...this.state.cart,
        photos: _.without(this.state.cart.photos, photo.id),
        prepaidPhotos: _.concat(
          _.without(this.state.cart.prepaidPhotos, photo.id),
          substitutePrepaidPhoto || []
        )
      }
    });

    ajax
      .delete(this.props.removeFromCartAPI)
      .send({ photo_id: photo.id, cart_id: this.state.cart.id })
      .then(_ => {
        //@ts-ignore
        toastr.success("Removed from cart", null, {
          timeOut: 1000
        });
        this.checkDiscountAndGetStatus();
        $.getScript(withCartIdParam("/cart_sales_count", this.state.cart.id));
      });
  };

  emptyPhotosFromCart = async () => {
    let response = await ajax.delete(this.props.emptyCartAPI).send({
      cart_id: this.state.cart ? this.state.cart.id : null
    });
    if (response.status === 200) {
      this.setState({
        cart: response.body.cart
      });
      $.getScript(withCartIdParam("/cart_sales_count", this.state.cart.id));
    }
  };

  emptyPhotosFromPrepaidCart = () => {
    ajax
      .delete(this.props.emptyCartAPI)
      .send({
        cart_id: this.state.cart ? this.state.cart.id : null
      })
      .then((response: any) => {
        this.setState({
          cart: response.body.cart
        });
        $.getScript(withCartIdParam("/cart_sales_count", this.state.cart.id));

        // @ts-ignore
        toastr.success("Your current cart has been cleared.");
      });
  };

  availPhotoBundleDiscount = async () => {
    if (this.state.cart && this.state.cart.id) {
      await this.emptyPhotosFromCart();
    }
    ajax
      .post(this.props.availPhotoBundleDiscountAPI)
      .send({
        cart_id: this.state.cart ? this.state.cart.id : null,
        event_id: this.props.event.id
      })
      .then(res => {
        this.setState({
          cart: {
            ...this.state.cart,
            ...res.body.cart
          }
        });
      });
  };

  removePhotoBundleDiscount = async () => {
    await ajax
      .post(this.props.withdrawPhotoBundleDiscountAPI)
      .send({
        cart_id: this.state.cart.id,
        event_id: this.props.event.id
      })
      .then(res => {
        this.setState({
          cart: {
            ...this.state.cart,
            ...res.body.cart
          }
        });
      })
      .catch(err => console.log(err));
  };

  handleSelfiePrepaidCustomerCreation = (referenceImageURL: string) => {
    this.setState(
      {
        selfiePrepaidStatus: "unavailable"
      },
      () => {
        ajax
          .post(this.props.createPrepaidCustomerAPI)
          .send({
            reference_image_url: referenceImageURL
          })
          .then(res => {
            this.setState({
              cart: res.body.updatedCart,
              prepaidCustomer: res.body.prepaidCustomer,
              selfiePrepaidStatus: res.body.prepaidCustomer
                ? "available"
                : "availed"
            });
          });
      }
    );
  };

  handleCookiePrepaidCustomerCreation = () => {
    if (!this.props.cookiePrepaidAvailed && !this.state.prepaidCustomer)
      ajax
        .post(this.props.createPrepaidCustomerAPI)
        .send({
          email: this.state.guestUserId && this.state.guestUserEmail
        })
        .then(res => {
          this.setState({
            cart: res.body.updatedCart,
            prepaidCustomer: res.body.prepaidCustomer
          });
        });
  };

  unlinkSelfiePrepaidCustomer = () => {
    ajax
      .delete(
        withCartIdParam(this.props.unlinkPrepaidCustomerAPI, this.state.cart.id)
      )
      .then(res => {
        this.setState({
          cart: res.body.updatedCart,
          prepaidCustomer: res.body.prepaidCustomer,
          selfiePrepaidStatus: "unavailable"
        });
      });
  };

  reportCookieIssue = () => {
    //@ts-ignore
    Rollbar.configure({
      payload: {
        context: "BrowseEvent#event"
      }
    });
    //@ts-ignore
    Rollbar.error("Cookies failing in BrowseEvent", {
      embedded: this.props.embedded
    });

    return true;
  };

  logoutPrepaidCustomer = async (availBundleOffer: boolean) => {
    //@ts-ignore
    toastr.success("Logging you out now...");
    await ajax
      .delete(
        withCartIdParam(this.props.logoutPrepaidCustomerAPI, this.state.cart.id)
      )
      .then(response => {
        this.setState({
          prepaidCustomer: {} as BrowseEventProps["prepaidCustomer"]
        });
        if (availBundleOffer && this.state.cart.photos.length > 0) {
          //@ts-ignore
          this.setState({ clearCartPopup: true });
        } else if (
          availBundleOffer &&
          !getCookie("reference_image_for_event")
        ) {
          this.setState({ selfieUploadPopup: true });
        }
      })
      .catch(() =>
        //@ts-ignore
        toastr.failure("Error logging out. Please try again.")
      );
  };

  clearMediaIds = () => this.setState({ listOfMediaId: [] });

  getPhotoId = (
    photo: { id: number; hidden: boolean },
    index: any,
    photos: any
  ) => {
    const listofIDs = [...this.state.listOfMediaId];
    const listOfMedia = [...photos];
    listOfMedia[index].isSelected = true;
    const arrayOfId = listofIDs.filter(i => i.id === photo.id);
    if (arrayOfId.length === 0) {
      listofIDs.push({ id: photo.id, hidden: photo.hidden });
      this.setState({ listOfMediaId: listofIDs });
    } else {
      let indexOfId = -1;
      listofIDs.forEach((i, index) => {
        if (i.id === photo.id) {
          indexOfId = index;
        }
      });
      listOfMedia[index].isSelected = false;
      listofIDs.splice(indexOfId, 1);
      this.setState({ listOfMediaId: listofIDs });
    }
  };

  selectAllVisible = () => {
    let listofIDs: any[] = [];
    if (!this.state.selectAll) {
      this.state.visiblePhotos.forEach((element: any) => {
        element.isSelected = true;
        listofIDs.push({ id: element.id, hidden: element.hidden });
      });
    } else {
      this.state.visiblePhotos.forEach((element: any) => {
        element.isSelected = false;
      });
      listofIDs = [];
    }

    this.setState({
      listOfMediaId: listofIDs,
      selectAll: !this.state.selectAll
    });
  };

  curatePhotos = async () => {
    const { listOfMediaId } = this.state;
    const arrayOfIds = listOfMediaId.map(item => item.id);
    try {
      const res = await $.ajax({
        url: `/api/v1/photos/${this.hideOrUnhideText()}`,
        type: "POST",
        data: {
          photo_ids: arrayOfIds,
          event_id: this.props.event.id
        }
      });

      //@ts-ignore
      toastr.success("Successfully updated");
      Telemetry.track(`event-gallery-curate:${this.hideOrUnhideText()}-photos`);
      this.setState({
        listOfMediaId: [],
        deletePhotosPopup: false,
        selectAll: false,
        reset: true
      });
    } catch (err) {
      //@ts-ignore
      toastr.error("Failed to update");
      console.error(err);
    }
  };

  deletePhotos = async () => {
    const { listOfMediaId } = this.state;
    const arrayOfIds = listOfMediaId.map(item => item.id);
    try {
      const res = await $.ajax({
        url: `/api/v1/photos/delete`,
        type: "DELETE",
        data: {
          photo_ids: arrayOfIds,
          event_id: this.props.event.id
        }
      });

      //@ts-ignore
      toastr.success("Successfully updated");
      Telemetry.track("event-gallery-curate:photos-deleted");
      this.setState({
        listOfMediaId: [],
        selectAll: false,
        reset: true,
        deletePhotosPopup: false
      });
    } catch (err) {
      //@ts-ignore
      toastr.error("Failed to update");
      console.error(err);
    }
  };

  reLoadAlbumPhotos = () => {
    this.setState({ reset: true });
  };

  hideOrUnhideText = () => {
    if (this.state.listOfMediaId.length === 0) return "hide";
    const hideOrUnhide = this.state.listOfMediaId.filter(i =>
      i.hidden != true ? true : false
    );
    return hideOrUnhide.length > 0 ? "hide" : "unhide";
  };

  setGuestUserCart = (userId: number, userEmail: string, cart: Cart) => {
    this.setState(
      { guestUserId: userId, guestUserEmail: userEmail, cart: cart },
      () => {
        this.trackCartEmailPopupActions("event-gallery:cart-email-provided");
        if (this.state.photoAddedToCart) {
          this.addPhotoToCart(
            this.state.photoAddedToCart,
            this.state.filterSelection
          );
        }
      }
    );
  };

  getReferenceImage = () => {
    const cookie = getCookie("reference_image_for_event");
    const [eventId, referenceImage] = cookie ? cookie.split("-imageURL-") : [];
    const parsedEventId = eventId ? parseInt(eventId) : null;

    if (cookie && parsedEventId != this.props.event.id) {
      //return null if none of these exist or if cookie exists but the event ID doesn't match
      return null;
    } else if (parsedEventId === this.props.event.id && referenceImage) {
      //return reference image if either of the above conditions are true
      return referenceImage;
    }
    return null;
  };

  showGalleryActions = () => {
    return (
      (!this.state.isProtected && !this.props.event.isPrivate) ||
      (!this.state.isProtected &&
        this.props.event.isPrivate &&
        this.state.filterSelection &&
        this.state.filterSelection.referenceImage)
    );
  };

  closeSaveCartModal = async () => {
    if (this.state.photoAddedToCart && !this.prepaidAuthCookie()) {
      await this.addPhotoToCart(
        this.state.photoAddedToCart,
        this.state.filterSelection
      );
    }
    this.setState({ showSaveCartModal: false });
  };

  setSaveCartPromptShown = () =>
    localStorage.setItem("isUserAskedForEmailToSaveCart", "true");

  toggleSideBar = () => {
    this.setState(
      prevState => ({ isSidebarOpen: !prevState.isSidebarOpen }),
      () => {
        const mainContainer = document.getElementById("event-container");
        const fakeButton = document.getElementById("sidebar-arrow-circle");
        if (mainContainer && fakeButton) {
          if (this.state.isSidebarOpen) {
            mainContainer.classList.add("container-small");
            fakeButton.classList.add("open");
          } else {
            mainContainer.classList.remove("container-small");
            fakeButton.classList.remove("open");
          }
        }
      }
    );
  };

  render() {
    return (
      <div id="browse-event">
        <div>
          {!this.props.isCustomerOnly && (
            <Sidebar
              isSidebarOpen={this.state.isSidebarOpen}
              toggleSideBar={this.toggleSideBar}
              userSignedIn={this.props.userSignedIn}
              eventId={this.props.event.id}
              albumObject={this.state.album ? this.state.album[0] : null}
            />
          )}
          {this.state.publishState === "unpublished" &&
            this.props.event.canUpdatePrivacy && (
              <PublishBanner
                openPublishPopup={() => this.setState({ publishPopup: true })}
              />
            )}
          <div className="geo-tog-content">
            <h1>
              <span className="geo-eg-name">
                {this.state.albumModeID
                  ? this.state.album
                    ? this.state.album[0].name
                    : ""
                  : this.props.event.name}{" "}
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => this.setState({ showShareGalleryModal: true })}
                >
                  <ShareIcon
                    color={this.props.pageConfiguration.primaryColor}
                  />
                </span>
              </span>
            </h1>
            <div className="geo-content-btn">
              {this.showGalleryActions() && this.props.canManageTags ? (
                <div className="tag-mode-tog">
                  {" "}
                  <span
                    className="geo-form__togtext"
                    style={{ marginRight: 5 }}
                  >
                    Curate
                  </span>
                  <label className="switch geo-form__toggle">
                    <input
                      type="checkbox"
                      className="geo-form__toginput"
                      onChange={() =>
                        this.setState(
                          { curationMode: !this.state.curationMode },
                          () =>
                            this.state.curationMode
                              ? this.emptyPhotosFromCart()
                              : this.clearMediaIds()
                        )
                      }
                    />
                    <span
                      className="geo-form__tog"
                      style={
                        this.state.curationMode
                          ? {
                              background:
                                this.props.pageConfiguration.primaryColor
                            }
                          : {}
                      }
                    >
                      <span className="geo-form__tog-in"></span>
                    </span>
                  </label>
                </div>
              ) : (
                this.props.showInvitePhotographerModal && (
                  <div
                    className="invite-ph-box"
                    onClick={() => {
                      Telemetry.track(
                        "internal-referrals:event-gallery-ph-referral"
                      );
                      this.setState({ showInvitePopup: true });
                    }}
                  >
                    <img src={wave} />
                    <div>
                      {" "}
                      Are you a <br />
                      photographer?
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
          <h6 className="geo-eg-date">
            Event date - {this.props.event.date}
            <div className="geo-csupport__wrap">
              {this.props.event.supportEmail && (
                <span
                  className="geo-csupport__icon"
                  onClick={() => this.setState({ showEmailpopup: true })}
                >
                  <span
                    style={{
                      color: this.props.pageConfiguration.primaryColor
                    }}
                  >
                    {this.props.event.eventSupportLabel
                      ? this.props.event.eventSupportLabel
                      : "Contact Support"}
                  </span>
                </span>
              )}
              {this.state.showEmailpopup && (
                <div className="geo-popover geo-popover--right">
                  <div
                    className="geo-popover__close"
                    onClick={() => this.setState({ showEmailpopup: false })}
                  >
                    &times;
                  </div>
                  <div className="geo-popover__body">
                    <EventSupportEmailPopup
                      showEmail={this.state.showEmailpopup}
                      closePopup={() =>
                        this.setState({ showEmailpopup: false })
                      }
                      eventSupportLabel={this.props.event.eventSupportLabel}
                      supportEmail={this.props.event.supportEmail}
                    />
                  </div>
                </div>
              )}
            </div>
          </h6>
          {this.state.promptPrepaidAuth && (
            <PrepaidCustomerAuthPopup
              event={this.props.event}
              authenticatePrepaidCustomerAPI={
                this.props.authenticatePrepaidCustomerAPI
              }
              setPrepaidCustomer={(prepaidCustomer: any) => {
                this.setState(
                  {
                    prepaidCustomer: prepaidCustomer,
                    promptPrepaidAuth: false
                  },
                  () => this.discountMessage()
                );
              }}
              setUpdatedCart={(updatedCart: any) => {
                this.setState({ cart: updatedCart });
              }}
              cancelPrepaidAuth={() =>
                this.setState({ promptPrepaidAuth: false }, () =>
                  removeParam("prepaid-auth", window.location.href)
                )
              }
              cartId={this.props.cart ? this.props.cart.id : null}
            />
          )}
          {this.showGalleryActions() && this.state.curationMode && (
            <BulkTagging
              showBulkTagging={() => this.setState({ showBulkTagging: true })}
              confirmDelete={() => this.setState({ deletePhotosPopup: true })}
              curatePhotos={this.curatePhotos}
              hideOrUnhideText={this.hideOrUnhideText()}
              selectAllVisible={this.selectAllVisible}
              listOfMediaId={this.state.listOfMediaId}
              filterActive={this.state.filterActive}
              allVisible={this.state.selectAll}
              canDeletePhotos={this.props.canDeletePhotos}
              colorStyling={this.props.pageConfiguration.primaryColor}
            />
          )}
          <EventContainer
            event={this.props.event}
            albumsAPI={this.props.albumsAPI}
            registerCustomerLeadAPI={this.props.registerCustomerLeadAPI}
            registerGuestUserAPI={this.props.registerGuestUserAPI}
            addPhotoToFavoritesAPI={this.props.addPhotoToFavoritesAPI}
            removePhotoFromFavoritesAPI={this.props.removePhotoFromFavoritesAPI}
            userSignedIn={this.props.userSignedIn}
            userId={this.props.userId}
            userEmail={this.props.userEmail}
            cart={this.state.cart}
            addPhotoToCart={this.addPhotoToCart}
            removePhotoFromCart={this.removePhotoFromCart}
            initialFilters={this.props.initialFilters}
            photoTimeRange={this.props.photoTimeRange}
            discountMessage={this.state.discountMessage}
            cartFullPrice={this.state.cartFullPrice}
            cartDiscountedPrice={this.state.cartDiscountedPrice}
            cartHasDiscount={this.state.cartHasDiscount}
            promptPrepaidCustomerAuth={() =>
              this.setState({ promptPrepaidAuth: true })
            }
            emptyPhotosFromCart={this.emptyPhotosFromCart}
            prepaidCustomersEnabled={this.props.event.prepaidCustomersEnabled}
            handleSelfiePrepaidCustomerCreation={
              this.handleSelfiePrepaidCustomerCreation
            }
            logoutPrepaidCustomer={this.logoutPrepaidCustomer}
            selfieSearchS3Bucket={this.props.selfieSearchS3Bucket}
            photoBundleDiscountAvailable={
              this.props.photoBundleDiscountAvailable
            }
            removePhotoBundleDiscount={this.removePhotoBundleDiscount}
            setSelfieUploadPopup={this.setSelfieUploadPopup}
            selfieUploadPopup={this.state.selfieUploadPopup}
            photosAvailable={this.props.photosAvailable}
            curationMode={this.state.curationMode}
            listOfMediaId={this.state.listOfMediaId}
            setFilterActive={this.setFilterActive}
            unlinkSelfiePrepaidCustomer={this.unlinkSelfiePrepaidCustomer}
            clearCartPopup={this.state.clearCartPopup}
            setClearCartPopup={(val: boolean) =>
              this.setState({ clearCartPopup: val })
            }
            setGuestUserCart={this.setGuestUserCart}
            guestUserId={this.state.guestUserId}
            availPhotoBundleDiscount={this.availPhotoBundleDiscount}
            showGalleryActions={this.showGalleryActions}
            setAlbum={(val: any) => this.setState({ album: val })}
            album={this.state.album}
          />
          {this.props.embedded ? <EmbedHeader cart={this.state.cart} /> : null}
          <StyleOverrides
            primaryColor={this.props.pageConfiguration.primaryColor}
            secondaryColor={this.props.pageConfiguration.secondaryColor}
          />
          {this.state.showShareGalleryModal ? (
            <ShareSocialPopup
              galleryURL={this.props.galleryURL}
              hideShareGalleryModal={() =>
                this.setState({ showShareGalleryModal: false })
              }
              text={"Share with participants or just show off ;)"}
            />
          ) : null}
          {this.state.showBulkTagging && (
            <BundleMediaSelect
              listOfMediaId={this.state.listOfMediaId}
              mediaCount={this.state.listOfMediaId.length}
              appliedTags={[]}
              contentTagsAPI={"/api/v1/content_tags"}
              handleClose={() => this.setState({ showBulkTagging: false })}
              handlePostCall={() => (
                this.clearMediaIds(),
                this.setState({ listOfMediaId: [], selectAll: false })
              )}
            />
          )}
          {this.state.deletePhotosPopup && (
            <DeletePhotosConfirmation
              closePopup={() => this.setState({ deletePhotosPopup: false })}
              deletePhotos={this.deletePhotos}
            />
          )}
          {this.state.clearPrepaidCartPopup && (
            <PromptClearPrepaidCart
              closePopup={() => this.setState({ clearPrepaidCartPopup: false })}
              clearCart={() => {
                this.emptyPhotosFromPrepaidCart();
              }}
              primaryAuth={this.props.event.prepaidCustomersPrimaryAuthField}
            />
          )}
          {this.state.publishPopup && (
            <PublishingOptions
              closePopup={() => this.setState({ publishPopup: false })}
              updateEventPrivacy={(id: number, val: { publish_mode: string }) =>
                this.setState({ publishState: val.publish_mode })
              }
              schoolUser={this.props.schoolUser}
              eventData={{
                id: this.props.event.id,
                name: this.props.event.name,
                url: this.props.galleryURL,
                publishMode: this.props.event.publishMode,
                password: ""
              }}
            />
          )}
          {this.state.showSaveCartModal ? (
            <SaveCart
              closeSaveCartModal={this.closeSaveCartModal}
              setSaveCartPromptShown={this.setSaveCartPromptShown}
              setGuestUserCart={this.setGuestUserCart}
              trackCartEmailPopupActions={this.trackCartEmailPopupActions}
              emailMandatory={this.prepaidAuthCookie()}
            />
          ) : null}
          {this.state.showInvitePopup && (
            <InvitePhotographerPopup
              handleCloseClick={() => this.setState({ showInvitePopup: false })}
              type={"desktop"}
            />
          )}
        </div>
      </div>
    );
  }
}
